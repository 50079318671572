interface ParameterInterface {
    id: number;
    name: string;
    value: string;
}

class Parameter implements ParameterInterface {
    id = 0;
    name = '';
    value = '';
}

export {Parameter}