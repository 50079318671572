






































































































































import Vue from "vue";
import { Order } from '@/models/Order'
import store from '@/store'

export default Vue.extend({
    name: 'HomePage',

    data: () => {
        return {
            dlgFinish: false,
            dlgRenew: false,
            dlgPay: false,
            editItem: new Order,
            search: '',
            headers: [
                { text: "Cliente", value: "customer.name" },
                { text: "Conteiner", value: "conteiner.code" },
                { text: "Início", value: "startdate" },
                { text: "Prev. Fim", value: "enddate" },
                { text: "Endereço", value: "address" },
                { text: "Telefone", value: "phone" },
                { text: "Observações", value: "information" },
                { text: 'Dias', value: "days"},
                { text: 'Taxas', value: "calc_tax", align: "end"},
                { text: 'Pago', value: "paid", align: "center"},
                { text: "Ações", value: "actions", width: "100px", align: "center" },
            ], 
        }
    },

    methods: {
        calcTax(item: Order): number {
            if (item.tax) {
                return Math.abs(this.$moment(item.startdate).diff(new Date, 'days')) * store.getters.dailyTax
            }   
            return 0
        },

        calcDays(item: Order): number {
            const hoje = this.$moment()
            const startdate = this.$moment(item.startdate)
            if (startdate.isSameOrAfter(hoje)) {
                return 0;
            } else {
                return Math.abs(startdate.diff(hoje, 'days'))
            }
        },

        openDlgFinish(item: Order) {
            this.editItem = JSON.parse(JSON.stringify(item))
            this.editItem.enddate = this.$moment().format('YYYY-MM-DD')
            this.dlgFinish = true 
        },

        openDlgRenew(item: Order) {
            this.editItem = JSON.parse(JSON.stringify(item))
            this.dlgRenew = true 
        },

        openDlgPay(value: boolean, item: Order) {
            this.editItem = JSON.parse(JSON.stringify(item))
            this.editItem.paid = value;
            this.dlgPay = true
        },

        cancelPaid() {
            this.$store.commit('setLoading', true)
            this.$store.dispatch('getOrders').finally(() => {
                this.$store.commit('setLoading', false)
            })
            this.editItem = new Order
            this.dlgPay = false
        },

        finishOrderConfirm() {
            store.dispatch('finishOrder', this.editItem).finally(() => {
                this.editItem = new Order
                this.dlgFinish = false 
            })
        },

        renewOrderConfirm() {
            this.editItem.enddate = this.$moment(this.editItem.enddate).add(store.getters.renewTime, 'days').format('YYYY-MM-DD')
            this.editItem.renewed = Number(this.editItem.renewed) + 1
            store.dispatch('putOrder', this.editItem)
            this.editItem = new Order
            this.dlgRenew = false
        },

        setPaid() {
            store.dispatch('putOrder', this.editItem)
            this.editItem = new Order
            this.dlgPay = false
        }

    }
})
