import Vue from 'vue'
import Vuex from 'vuex'
import http from '@/plugins/axios'
import { Conteiner } from '@/models/Conteiner'
import { Customer } from '@/models/Customer'
import { Order, OrderInterface } from '@/models/Order'
import { Parameter } from '@/models/Parameter'
import { Toast } from '@/models/Toast'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isAuthenticated: false,
        loading: false,
        toastShow: false,
        toastMessage: '',
        toastColor: 'success',
        states: ['RO','AC','AM','RR','PA','AP','TO','MA','PI','CE','RN','PB','PE','AL','SE','BA','MG','ES','RJ','SP','PR','SC','RS','MS','MT','GO','DF'],
        conteiners: Array<Conteiner>(),
        customers: Array<Customer>(),
        orders: Array<Order>(), 
        parameters: Array<Parameter>(),
        requiredRule: [
            (v: any) => !!v || 'Campo obrigatório'
        ]
    },
    getters: {
        allConteiners: ({ conteiners }) => {
            return conteiners
        },
        freeConteiners: ({ conteiners }) => {
            return conteiners.filter(i => !i.busy && !i.inactive)
        },
        allCustomers: ({ customers }) => {
            return customers
        },
        allOrders: ({ orders }) => {
            return orders
        },
        openOrders: ({ orders }) => {
            return orders.filter((i: OrderInterface) => !i.finished)
        },
        ocupation: ({ conteiners }) => {
            const ocupados = conteiners.filter(i => i.busy && !i.inactive)
            return Number((ocupados.length / conteiners.length) * 100).toFixed(2)
        },
        dailyTax: ({ parameters }) => {
            return parameters.find(i => i.name == 'daily_tax')?.value || 0
        },
        renewTime: ({ parameters }) => {
            return parameters.find(i => i.name == 'renew_time')?.value || 0
        }
    },
    mutations: {
        setAuthenticated(state, value) {
            state.isAuthenticated = value
        },
        setConteiners(state, payload: Array<Conteiner>) {
            state.conteiners = payload
        },
        addConteiner(state, payload: Conteiner) {
            state.conteiners.push(payload)
        },
        editConteiner(state, payload: Conteiner) {
            const searchElement = state.conteiners.find(c => c.id == payload.id)
            if (searchElement) {
                const index = state.conteiners.indexOf(searchElement)
                state.conteiners.splice(index, 1, payload)
            }
        },
        deleteConteiner(state, payload: Conteiner) {
            const searchElement = state.conteiners.find(c => c.id == payload.id)
            if (searchElement) {
                const index = state.conteiners.indexOf(searchElement)
                state.conteiners.splice(index, 1)
            }
        },
        setCustomers(state, payload: Array<Customer>) {
            state.customers = payload
        },
        addCustomer(state, payload: Customer) {
            state.customers.push(payload)
        },
        editCustomer(state, payload: Customer) {
            const searchElement = state.customers.find(c => c.id == payload.id)
            if (searchElement) {
                const index = state.customers.indexOf(searchElement)
                state.customers.splice(index, 1, payload)
            }
        },
        deleteCustomer(state, payload: Customer) {
            const searchElement = state.customers.find(c => c.id == payload.id)
            if (searchElement) {
                const index = state.customers.indexOf(searchElement)
                state.customers.splice(index, 1)
            }
        },
        setOrders(state, payload: Array<Order>) {
            state.orders = payload
        },
        addOrder(state, payload: Order) {
            state.orders.push(payload)
        },
        editOrder(state, payload: Order) {
            const searchElement = state.orders.find(c => c.id == payload.id)
            if (searchElement) {
                const index = state.orders.indexOf(searchElement)
                state.orders.splice(index, 1, payload)
            }
        },
        deleteOrder(state, payload: Order) {
            const searchElement = state.orders.find(c => c.id == payload.id)
            if (searchElement) {
                const index = state.orders.indexOf(searchElement)
                state.orders.splice(index, 1)
            }
        },
        setParameters(state, payload: Array<Parameter>) {
            state.parameters = payload
        },
        addParameter(state, payload: Parameter) {
            state.parameters.push(payload)
        },
        editParameter(state, payload: Parameter) {
            const searchElement = state.parameters.find(c => c.id == payload.id)
            if (searchElement) {
                const index = state.parameters.indexOf(searchElement)
                state.parameters.splice(index, 1, payload)
            }
        },
        deleteParameter(state, payload: Parameter) {
            const searchElement = state.parameters.find(c => c.id == payload.id)
            if (searchElement) {
                const index = state.parameters.indexOf(searchElement)
                state.parameters.splice(index, 1)
            }
        },
        setLoading(state, payload: boolean) {
            state.loading = payload
        },
        showToast(state, payload: Toast) {
            state.toastColor = payload.color
            state.toastMessage = payload.message
        }
    },
    actions: {
        getConteiners({ commit }) {
            return http.get('conteiners').then(res => {
                commit('setConteiners', res.data)
            })
        },

        postConteiner({ commit }, payload) {
            commit('setLoading', true)
            return http.post('conteiners', payload).then(res => {
                commit('addConteiner', res.data)
            }).finally(() => {
                commit('setLoading', false)
            })
        },

        putConteiner({ commit }, payload) {
            commit('setLoading', true)
            return http.put('conteiners/' + payload.id, payload).then(res => {
                commit('editConteiner', res.data)
            }).finally(() => {
                commit('setLoading', false)
            })
        },

        delConteiner({ commit }, payload) {
            commit('setLoading', true)
            return http.delete('conteiners/' + payload.id).then(res => {
                commit('deleteConteiner', payload)
            }).finally(() => {
                commit('setLoading', false)
            })
        },

        getCustomers({ commit }) {
            return http.get('customers').then(res => {
                commit('setCustomers', res.data)
            })
        },

        postCustomer({ commit }, payload) {
            commit('setLoading', true)
            return http.post('customers', payload).then(res => {
                commit('addCustomer', res.data)
            }).finally(() => {
                commit('setLoading', false)
            })
        },

        putCustomer({ commit }, payload) {
            commit('setLoading', true)
            return http.put('customers/' + payload.id, payload).then(res => {
                commit('editCustomer', res.data)
            }).finally(() => {
                commit('setLoading', false)
            })
        },

        delCustomer({ commit }, payload) {
            commit('setLoading', true)
            return http.delete('customers/' + payload.id).then(res => {
                commit('deleteCustomer', payload)
            }).finally(() => {
                commit('setLoading', false)
            })
        },

        getViaCEP({ commit }, payload) {
            return fetch('https://viacep.com.br/ws/'+ String(payload).replace(/\D+/g, '') +'/json/').then(resp => resp.json())
        },

        getOrders({ commit }) {
            return http.get('orders').then(res => {
                commit('setOrders', res.data)
            })
        },

        postOrder({ commit }, payload) {
            commit('setLoading', true)
            return http.post('orders', payload).then(res => {
                commit('addOrder', res.data)
                commit('editConteiner', res.data.conteiner)
            }).finally(() => {
                commit('setLoading', false)
            })
        },

        putOrder({ commit, dispatch }, payload) {
            commit('setLoading', true)
            return http.put('orders/' + payload.id, payload).then(res => {
                commit('editOrder', res.data)
                dispatch('getConteiners')
            }).finally(() => {
                commit('setLoading', false)
            })
        },

        delOrder({ commit, state }, payload) {
            commit('setLoading', true)
            return http.delete('orders/' + payload.id).then(res => {
                commit('deleteOrder', payload)
                const conteiner = state.conteiners.find(i => i.id == payload.conteiner_id)
                if (conteiner) {
                    conteiner.busy = false
                    commit('editConteiner', conteiner)
                }
            }).finally(() => {
                commit('setLoading', false)
            })
        },

        getParameters({ commit }) {
            return http.get('parameters').then(res => {
                commit('setParameters', res.data)
            })
        },

        postParameter({ commit }, payload) {
            commit('setLoading', true)
            return http.post('parameters', payload).then(res => {
                commit('addParameter', res.data)
            }).finally(() => {
                commit('setLoading', false)
            })
        },

        putParameter({ commit }, payload) {
            commit('setLoading', true)
            return http.put('parameters/' + payload.id, payload).then(res => {
                commit('editParameter', res.data)
            }).finally(() => {
                commit('setLoading', false)
            })
        },

        delParameter({ commit }, payload) {
            commit('setLoading', true)
            return http.delete('parameters/' + payload.id).then(res => {
                commit('deleteParameter', payload)
            }).finally(() => {
                commit('setLoading', false)
            })
        },

        finishOrder({ commit, dispatch }, payload) {
            commit('setLoading', true)
            return http.post('orders/finish', JSON.parse(JSON.stringify(payload))).then(res => {
                commit('editOrder', res.data)
                dispatch('getConteiners')
            }).finally(() => {
                commit('setLoading', false)
            })
        }
    },
    modules: {
    }
})

