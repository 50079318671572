<template>
  <div>
    <v-app-bar color="primary" dark dense>
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
        <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" temporary absolute>
        <v-list nav dense>
            <v-list-item-group v-model="group">
                <v-list-item to="/">
                    <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Home</v-list-item-title>
                </v-list-item>

                <v-list-item to="/conteiners">
                    <v-list-item-icon>
                        <v-icon>mdi-grave-stone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Conteiners</v-list-item-title>
                </v-list-item>

                <v-list-item to="/customers">
                    <v-list-item-icon>
                        <v-icon>mdi-account-multiple-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Clientes</v-list-item-title>
                </v-list-item>

                <v-list-item to="/orders">
                    <v-list-item-icon>
                        <v-icon>mdi-script-text-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Reservas</v-list-item-title>
                </v-list-item>

                <v-list-item to="/parameters">
                    <v-list-item-icon>
                        <v-icon>mdi-view-grid-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Parâmetros</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "NavBar",

  data: () => ({
    drawer: false,
    group: null,
  }),
};
</script>

<style>
</style>