import { Conteiner } from "./Conteiner";
import { Customer } from "./Customer";
import store from "@/store";

interface OrderInterface {
    id: number;
    startdate: string;
    enddate: string;
    renewed: number;
    customer_id: number;
    conteiner_id: number;
    zipcode: string;
    address: string;
    district: string;
    city: string;
    state: string;
    created_at: Date;
    updated_at: Date;
    finished?: Date;
    tax: boolean;
    tax_value: number;
    customer: Customer;
    conteiner: Conteiner;
    paid: boolean;
    phone: string;
    information: string;
}

class Order implements OrderInterface {
    id = 0;
    startdate = '';
    enddate = '';
    renewed = 0;
    customer_id = 0;
    conteiner_id = 0;
    zipcode = '';
    address = '';
    district = '';
    city = '';
    state = 'RS';
    tax = false;
    tax_value = 0;
    created_at = new Date;
    updated_at = new Date;
    customer = new Customer;
    conteiner = new Conteiner;
    paid = false;
    phone = '';
    information = '';
}

export {Order, OrderInterface}