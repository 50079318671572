import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import store from '@/store'

import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Conteiners from '@/views/Conteiners.vue'
import Customers from '@/views/Customers.vue'
import Orders from '@/views/Orders.vue'
import Parameters from '@/views/Parameters.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/conteiners',
        name: 'Conteiners',
        component: Conteiners
    },
    {
        path: '/customers',
        name: 'Clientes',
        component: Customers
    },
    {
        path: '/orders',
        name: 'Reservas',
        component: Orders
    },
    {
        path: '/parameters',
        name: 'Parâmetros',
        component: Parameters
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'Login' && !store.state.isAuthenticated) {
        next({ name: 'Login' })
    } else {
        next()
    }
})

export default router
