"use strict";

import Vue from 'vue'
import axios from "axios";
import router from '../router'
import store from '@/store';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
    baseURL: process.env.VUE_APP_API || "",
    timeout: 60 * 1000, // Timeout
    withCredentials: false, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        config.headers['Authorization'] = 'Bearer ' + Vue.$cookies.get('token');
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        // Do something with response data
        return response;
    },
    function (error) {
        console.error(error)
        // Do something with response error
        if (error.response.status == 401) {
            router.push('login')
        }
        return Promise.reject(error);
    }
);

Vue.prototype.$http = _axios

export default _axios
