interface CustomerInterface {
    id: number;
    name: string;
    document: string;
    phone: string;
    celphone: string;
    zipcode: string;
    address: string;
    district: string;
    city: string;
    state: string;
}

class Customer implements CustomerInterface {
    id = 0;
    name = '';
    document = '';
    phone = '';
    celphone = '';
    zipcode = '';
    address = '';
    district = '';
    city = '';
    state = 'RS';
}

export {Customer}

