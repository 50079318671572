














































































import Vue from "vue";
import store from "@/store";
import { Conteiner } from "@/models/Conteiner";

export default Vue.extend({
  name: "ConteinersPage",

  data: () => {
    return {
      dialog: false,
      dialogDelete: false,
      search: '',
      headers: [
        { text: "Código", value: "code" },
        { text: "Ocupado", value: "busy", align: "center" },
        { text: "Inativo", value: "inactive", align: "center" },
        { text: "Ações", value: "actions", width: "80px", align: "center" },
      ],
      editedIndex: -1,
      editedItem: new Conteiner(),
    };
  },

  mounted() {
    store.dispatch("getConteiners");
  },

  methods: {
    save() {
      if (this.editedItem.code)
        if (this.editedIndex >= 0) {
          this.$store.dispatch("putConteiner", this.editedItem).finally(() => {
            this.editedIndex = -1;
            this.dialog = false;
            this.editedItem = new Conteiner();
          });
        } else {
          this.$store.dispatch("postConteiner", this.editedItem).finally(() => {
            this.editedIndex = -1;
            this.dialog = false;
            this.editedItem = new Conteiner();
          });
        }
    },

    editItem(item: any) {
      this.editedIndex = this.$store.state.conteiners.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    deleteItem(item: Conteiner) {
      this.editedIndex = this.$store.state.conteiners.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
        this.$store.dispatch("delConteiner", this.editedItem).finally(() => {
            this.editedIndex = -1;
            this.dialogDelete = false;
            this.editedItem = new Conteiner();
        });
    },

    cancelOperation() {
        this.editedIndex = -1;
        this.dialogDelete = false;
        this.dialog = false
        this.editedItem = new Conteiner();
    }
  },
});
