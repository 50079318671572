












































import Vue from "vue";
import store from "@/store";
import { Parameter } from "@/models/Parameter";

export default Vue.extend({
  name: "ParametersPage",

  data: () => {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Nome", value: "name" },
        { text: "Valor", value: "value" },
        { text: "Ações", value: "actions", width: "80px", align: "center" },
      ],
      editedIndex: -1,
      editedItem: new Parameter(),
    };
  },

  mounted() {
    store.dispatch("getParameters");
  },

  methods: {
    save() {
        if (this.editedItem.name && this.editedItem.value)
            if (this.editedIndex >= 0) {
                this.$store.dispatch("putParameter", this.editedItem).finally(() => {
                    this.editedIndex = -1;
                    this.dialog = false;
                    this.editedItem = new Parameter();
                });
            } else {
                this.$store.dispatch("postParameter", this.editedItem).finally(() => {
                    this.editedIndex = -1;
                    this.dialog = false;
                    this.editedItem = new Parameter();
                });
            }
    },

    editItem(item: Parameter) {
      this.editedIndex = this.$store.state.parameters.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    cancelOperation() {
        this.editedIndex = -1;
        this.dialogDelete = false;
        this.dialog = false
        this.editedItem = new Parameter();
    }
  },
});
