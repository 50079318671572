interface ConteinerInterface {
    id: number;
    code: string;
    busy: boolean;
    inactive: boolean;
    created_at: Date;
    updated_at: Date;
}

class Conteiner implements ConteinerInterface {
    id = 0;
    code = '';
    busy = false;
    inactive = false;
    created_at = new Date;
    updated_at = new Date;
}

export {Conteiner}