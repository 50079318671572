







































































































import Vue from "vue";
import store from "@/store";
import { Customer } from "@/models/Customer";

export default Vue.extend({
  name: "CustomerPage",

  data: () => {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Nome", value: "name" },
        { text: "Telefone 1", value: "phone" },
        { text: "Telefone 2", value: "celphone" },
        { text: "Endereço", value: "address" },
        { text: "Ações", value: "actions", width: "80px", align: "center" },
      ],
      editedIndex: -1,
      editedItem: new Customer(),
      loadCep: false,
      search: ''
    };
  },

  mounted() {
    store.dispatch("getCustomers");
  },

  methods: {
    save() {
        if (!(this.$refs.formCustomer as Vue & { validate: () => boolean }).validate()) return
        if (this.editedIndex >= 0) {
          this.$store.dispatch("putCustomer", this.editedItem).finally(() => {
            this.editedIndex = -1;
            this.dialog = false;
            this.editedItem = new Customer();
          });
        } else {
          this.$store.dispatch("postCustomer", this.editedItem).finally(() => {
            this.editedIndex = -1;
            this.dialog = false;
            this.editedItem = new Customer();
          });
        }
    },

    editItem(item: any) {
      this.editedIndex = this.$store.state.customers.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    deleteItem(item: Customer) {
      this.editedIndex = this.$store.state.customers.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
        this.$store.dispatch("delConteiner", this.editedItem).finally(() => {
            this.editedIndex = -1;
            this.dialogDelete = false;
            this.editedItem = new Customer();
        });
    },

    cancelOperation() {
        this.editedIndex = -1;
        this.dialogDelete = false;
        this.dialog = false
        this.editedItem = new Customer();
    },

    getCep() {
        this.loadCep = true
        this.$store.dispatch('getViaCEP', this.editedItem.zipcode).then(resp => {
            this.editedItem.address = resp.logradouro
            this.editedItem.district = resp.bairro
            this.editedItem.city = resp.localidade
        }).finally(() => {
            this.loadCep = false
        })
    }
  },
});
