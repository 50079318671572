<template>
    <div>
        <v-container class="fill-height fundo align-center" fluid>
            <v-col>
                <v-row>
                    <v-img src="@/assets/LogoFull.png" contain :height="75"/>
                </v-row>
                <v-row align-content="center" justify="center" class="mt-5">
                    <v-card width="500px">
                        <v-card-text class="justify-center align-stretch">
                            <v-form id="form" ref="formulario" lazy-validation @submit.prevent="doLogin">
                                <v-text-field
                                    label="Login"
                                    prepend-icon="mdi-account-tie"
                                    required
                                    v-model="login"
                                    :rules="requiredRule"
                                />
                                <v-text-field
                                    label="Senha"
                                    prepend-icon="mdi-shield-key"
                                    v-model="senha"
                                    type="password"
                                    :rules="requiredRule"
                                />
                            </v-form>
                        </v-card-text>
                        <v-card-actions class="justify-center">
                            <v-btn color="primary" dark type="submit" form="form">Login</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-row>
            </v-col>
        </v-container>
        <v-snackbar v-model="snackbar" color="error" dark>
            <span>Credenciais inválidas!</span>
        </v-snackbar>
    </div>
</template>

<script>
import store from '@/store'

export default {
    name: 'LoginPage',
    data: () => {
        return {
            login: '',
            senha: '',
            requiredRule: [
                v => !!v || 'Campo é obrigatório'
            ],
            snackbar: false
        }
    },
    methods: {
        doLogin() {
            if (this.$refs.formulario.validate()) {
                const form = new FormData()
                form.append('login', this.login)
                form.append('password', this.senha)
                this.$http.post('login', form).then(resp => {
                    this.$cookies.set('token', resp.data.access_token);
                    this.$cookies.set('username', resp.data.username);
                    localStorage.setItem('expires_date', resp.data.expires)
                    store.commit('setAuthenticated', true)
                    store.commit('setLoading', true)
                    Promise.all([
                        store.dispatch("getParameters"),
                        store.dispatch("getConteiners"),
                        store.dispatch("getCustomers"),
                        store.dispatch("getOrders")
                    ]).finally(() => {
                        store.commit('setLoading', false)
                    })
                    this.$router.push('/')
                }).catch(() => {
                    this.snackbar = true
                })
            }
        }
    },
}
</script>

<style scoped>
.fundo {
    background-color: white;
    height: 100vh;
}
</style>