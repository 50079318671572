





































































































































































































import Vue from "vue";
import store from "@/store";
import { Order } from "@/models/Order";
import { Customer } from "@/models/Customer";

export default Vue.extend({
    name: "OrdersPage",

    data: () => {
        return {
            dialog: false,
            dialogDelete: false,
            headers: [
                { text: "Número", value: "id" },
                { text: "Cliente", value: "customer.name" },
                { text: "Conteiner", value: "conteiner.code" },
                { text: "Início", value: "startdate" },
                { text: "Fim", value: "finished" },
                { text: "Pago", value: "paid" },
                { text: "Endereço", value: "address" },
                { text: "Ações", value: "actions", width: "80px", align: "center" },
            ],
            search: '',
            editedIndex: -1,
            editedItem: new Order(),
            loadCep: false,
        };
    },

    mounted() {
        store.dispatch("getCustomers");
        store.dispatch("getConteiners");
        store.dispatch("getOrders");
    },

    methods: {
        save() {
            if (!(this.$refs.formOrder as Vue & {
                    validate: () => boolean;
                }).validate()
            ) return;
            if (this.editedIndex >= 0) {
                this.$store
                    .dispatch("putOrder", this.editedItem)
                    .finally(() => {
                        this.editedIndex = -1;
                        this.dialog = false;
                        this.editedItem = new Order();
                    });
            } else {
                this.$store
                    .dispatch("postOrder", this.editedItem)
                    .finally(() => {
                        this.editedIndex = -1;
                        this.dialog = false;
                        this.editedItem = new Order();
                    });
            }
        },

        editItem(item: Order) {
            this.editedIndex = this.$store.state.orders.indexOf(item);
            this.editedItem = { ...item };
            this.dialog = true;
        },

        deleteItem(item: Order) {
            this.editedIndex = this.$store.state.orders.indexOf(item);
            this.editedItem = { ...item };
            this.dialogDelete = true;
        },

        deleteItemConfirm() {
            this.$store
                .dispatch("delOrder", this.editedItem)
                .finally(() => {
                    this.editedIndex = -1;
                    this.dialogDelete = false;
                    this.editedItem = new Order();
                });
        },

        cancelOperation() {
            this.editedIndex = -1;
            this.dialogDelete = false;
            this.dialog = false;
            this.editedItem = new Order();
        },

        getCep() {
            this.loadCep = true;
            this.$store
                .dispatch("getViaCEP", this.editedItem.zipcode)
                .then((resp) => {
                    this.editedItem.address = resp.logradouro;
                    this.editedItem.district = resp.bairro;
                    this.editedItem.city = resp.localidade;
                })
                .finally(() => {
                    this.loadCep = false;
                });
        },

        changeCustomer(event: number) {
            if (this.editedIndex == -1) {
                const customer = this.$store.state.customers.find((i: Customer) => i.id == event);
                this.editedItem.zipcode = customer.zipcode
                this.editedItem.address = customer.address
                this.editedItem.district = customer.district
                this.editedItem.city = customer.city
                this.editedItem.state = customer.state
                this.editedItem.phone = customer.phone
            }
        },

        setEndDate() {
            this.editedItem.enddate = this.$moment(this.editedItem.startdate).add(store.getters.renewTime, 'day').format('YYYY-MM-DD');
        }
    },
});
